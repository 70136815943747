*, :after, :before {
  box-sizing: border-box;
}

body {
  font-family: Inter, system-ui, sans-serif;
  font-size: 14px;
}

#root {
  width: 40rem;
  margin: auto;
}

#root > details {
  color: #838383;
  background: #b8d7ff45;
  border: none;
  border-radius: 10px;
  padding: 10px 8px 8px 18px !important;
}

details {
  border-left: 1px solid #c4dcff;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 0 8px 8px 18px !important;
}

details > summary {
  background: #a7cfff59;
  border-radius: 10px;
  margin: .5rem .5rem 1rem;
  padding: 8px;
  font-weight: 600;
  padding-left: 12px !important;
}

details > details > summary {
  margin-bottom: 1rem;
  font-weight: 500;
}

blockquote {
  margin-inline-start: 40px;
  font-family: math;
  padding-left: 0 !important;
}

/*# sourceMappingURL=index.156f0cc1.css.map */
