*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	font-family: "Inter", system-ui, sans-serif;
	font-size: 14px;
}

#root {
	width: 40rem;
	margin: auto;
}

#root > details {
	background: #b8d7ff45;
	padding: 10px 8px 8px 18px !important;
	border: none;
	border-radius: 10px;
	color: #838383;
}

details {
	border-left: #c4dcff 1px solid;
	margin-left: 1rem;
	margin-top: 1rem;
	padding: 0 8px 8px 18px !important;
}

details > summary {
	margin: 0.5rem 0.5rem;
	background: #a7cfff59;
	border-radius: 10px;
	padding: 8px;
	margin-bottom: 1rem;
	padding-left: 12px !important;
	font-weight: 600;
}

details > details > summary {
	margin-bottom: 1rem;
	font-weight: 500;
}

blockquote {
	margin-inline-start: 40px;
	padding-left: 0 !important;
	font-family: math;
}
